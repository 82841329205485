import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'

import AxiosRequest from "../utils/axios-request"

# FILTERS
import toCurrency from '../filters/to_currency'

# Local imports
import CancelDelivery from '../methods/deliveries/cancel_delivery'
Vue.use TurbolinksAdapter

document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'show_delivery_customer'
  return false unless el?
  new Vue {
    el
    filters: {toCurrency}
    data: -> {
      products: []
      lots: []
      commission: 0
      trm: undefined
      customer_address: undefined
      colombia: 47
      are_m: undefined
      m_delivery: undefined
    }
    methods: {
      CancelDelivery
    }
    mounted: ->
      id = document.getElementById('id').dataset.id
      uid = document.getElementById('uid').dataset.uid
      query = $.get "/customers/#{uid}/deliveries/#{id}.json"
      query.done (res) =>
        @products = res.products
        @lots = res.lot_transactions
        @commission = res.commission
        @trm = res.trm
        @customer_address = res.customer_address
        @are_m =  res.are_m
        @m_delivery = res.m_delivery
    computed: {
      total_products: ->
        {products} = @
        return 0 if Object.keys(products).length == 0
        acum = 0
        for item in products
          acum += 1
        acum
      total_lots: ->
        {lots} = @
        return 0 if Object.keys(lots).length == 0
        acum = 0
        for item in lots
          acum += 1
        acum
      total: ->
        {products} = @
        return 0 if Object.keys(products).length == 0
        acum = 0
        for item in @products
          if item.total_paid_price > 0
            acum += parseInt item.total_paid_price
        acum
      total_usd: ->
        {products} = @
        return 0 if Object.keys(products).length == 0
        acum = 0
        for item in @products
          if item.total_paid_price_usd > 0
            acum += parseFloat item.total_paid_price_usd
        acum
      delivery: ->
        {products, are_m, m_delivery, colombia, customer_address} = @
        return 0 if Object.keys(products).length == 0
        total = 0
        delivery = 0
        for item in @products
          if item.total_paid_price > 0
            total += item.total_paid_price
        if total > parseInt(m_delivery.free_shipping)
          delivery = 0
        else
          if are_m
            delivery = parseInt(m_delivery.local)
          else if customer_address.country.idcountry == colombia
            delivery = parseInt(m_delivery.nacional)
          else
            delivery = parseInt(m_delivery.international)
        delivery
      delivery_usd: ->
        {products, are_m, m_delivery, colombia, customer_address, trm} = @
        return 0 if Object.keys(products).length == 0
        total = 0
        delivery = 0
        for item in @products
          if item.won_bid > 0
            total += item.won_bid
        if total > parseInt(m_delivery.free_shipping)
          delivery = 0
        else
          if are_m
            delivery = parseInt(m_delivery.local)
          else if customer_address.country.idcountry == colombia
            delivery = parseInt(m_delivery.nacional)
          else
            delivery = parseInt(m_delivery.international)
        parseFloat((delivery / trm).toPrecision(3))
      iva: ->
        {products} = @
        return 0 if Object.keys(products).length == 0
        acum = 0
        for item in @products
          if item.paid_iva > 0
            acum += parseInt item.paid_iva
        acum
      discount: ->
        {products} = @
        return 0 if Object.keys(products).length == 0
        acum = 0
        for item in @products
          if item.dicount > 0
            acum += parseInt item.dicount
        acum
      discount_usd: ->
        {products, trm} = @
        return 0 if Object.keys(products).length == 0
        acum = 0
        for item in @products
          if item.dicount > 0
            acum += item.dicount
        parseFloat((acum / trm).toPrecision(3))
      subtotal: ->
        {products} = @
        return 0 if Object.keys(products).length == 0
        acum = 0
        for item in @products
          if item.subtotal > 0
            acum += parseInt item.subtotal
        acum
      total_lot: ->
        {lots} = @
        return 0 if Object.keys(lots).length == 0
        acum = 0
        for item in @lots
          if item.won_bid > 0
            acum += parseInt item.won_bid
        acum
      total_lot_usd: ->
        {lots} = @
        return 0 if Object.keys(lots).length == 0
        acum = 0
        for item in @lots
          if item.won_bid_usd > 0
            acum += parseFloat item.won_bid_usd
        acum
      t_commission: ->
        {lots, commission} = @
        return 0 if Object.keys(lots).length == 0
        total = 0
        comm = 0
        for item in @lots
          if item.won_bid > 0
            total += item.won_bid
        comm = total * commission
      t_commission_usd: ->
        {lots, commission, trm} = @
        return 0 if Object.keys(lots).length == 0
        total = 0
        comm = 0
        for item in @lots
          if item.won_bid > 0
            total += item.won_bid
        comm = parseFloat(((total * commission) / trm).toPrecision(3))
      t_delivery: ->
        {lots, are_m, m_delivery, colombia, customer_address} = @
        return 0 if Object.keys(lots).length == 0
        total = 0
        delivery = 0
        for item in @lots
          if item.won_bid > 0
            total += item.won_bid
        if total > parseInt(m_delivery.free_shipping)
          delivery = 0
        else
          if are_m
            delivery = parseInt(m_delivery.local)
          else if customer_address.country.idcountry == colombia
            delivery = parseInt(m_delivery.nacional)
          else
            delivery = parseInt(m_delivery.international)
        delivery
      t_delivery_usd: ->
        {lots, are_m, m_delivery, colombia, customer_address, trm} = @
        return 0 if Object.keys(lots).length == 0
        total = 0
        delivery = 0
        for item in @lots
          if item.won_bid > 0
            total += item.won_bid
        if total > parseInt(m_delivery.free_shipping)
          delivery = 0
        else
          if are_m
            delivery = parseInt(m_delivery.local)
          else if customer_address.country.idcountry == colombia
            delivery = parseInt(m_delivery.nacional)
          else
            delivery = parseInt(m_delivery.international)
        parseFloat((delivery / trm).toPrecision(3))
    }
  }
