import AxiosRequest from "../../utils/axios-request"

export default (id, uid, state, state_name) ->
  final_data = {
    customer: uid,
    payment_delivery: id,
    cancel: true
  }
  AxiosRequest("/customers/#{uid}/deliveries/change_status", {final_data...}, 'post').then ({data}) ->
    Swal.fire({
      icon: 'success'
      title: data.message
    })
    location.reload()
